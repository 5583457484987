<template>
  <a-card>
    <template v-slot:title>
      <div>Edit Profile</div>
      <div class="card-subtitle">Change or reset your account password</div>
    </template>
    <div class="editor-container">
      <p class="editor-head">Username</p>
      <a-input disabled :default-value="userName" />
      <p class="editor-head">Email</p>
      <a-input v-model:value="newEmailInput" :default-value="userEmail" />
      <a-button type="primary" @click="onChangeProfile">Save Change</a-button>
    </div>
  </a-card>
</template>

<script>
import { ref, toRef, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import { notification } from 'ant-design-vue';

const EditProfile = {
  name: 'EditProfile',
  props: {
    userName: String,
    accountId: String,
    userId: String,
  },
  setup(props) {
    let accountId = toRef(props, 'accountId');
    let userId = toRef(props, 'userId');
    let newEmailInput = ref('');
    const store = useStore();
    const userEmail = computed(() => store.getters['account/userEmail']);

    onMounted(() => {
      newEmailInput.value = userEmail.value;
    });

    const onChangeProfile = async () => {
      console.log('save change');
      let data = { role: 'admin', email: newEmailInput.value };
      const options = {
        type: 'dom',
        'account-id': accountId.value,
        id: userId.value,
      };

      const result = await api.updateUser(data, options).catch((e) => {
        console.log(e);
      });
      if (result) {
        console.log(result);
        store.dispatch('account/setEmail', newEmailInput);
        notification.success({
          description: 'Your email has been changed successfully',
          message: 'Email Changed',
        });
      }
    };

    return {
      onChangeProfile,
      userEmail,
      newEmailInput,
    };
  },
};
export default EditProfile;
</script>
